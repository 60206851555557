export enum MitigationTypes {
  AGRICULTURE = 'Agriculture',
  BIOMASS_ENERGY = 'BiomassEnergy',
  CCS = 'CCS',
  CEMENT = 'Cement',
  COAL_MINE = 'Coal/Mine',
  EE_HOUSEHOLDS = 'EEHouseholds',
  EE_INDUSTRY = 'EEIndustry',
  EE_OWN_GENERATION = 'EEOwnGeneration',
  EE_SERVICE = 'EEService',
  EE_SUPPLY_SIDE = 'EESupplySide',
  ENERGY_DISTRIBUTION = 'EnergyDistribution',
  FORESTRY = 'Forestry',
  FOSSIL_FUEL = 'FossilFuel',
  FUGITIVE = 'Fugitive',
  GEOTHERMAL = 'Geothermal',
  HFC_PFCS_SF6 = 'HFC_PFCs_SF6',
  HYDRO = 'Hydro',
  LANDFILLS = 'Landfills',
  MARINE = 'Marine',
  METHANE_AVOIDANCE = 'MethaneAvoidance',
  N20 = 'N20',
  SOLAR = 'Solar',
  TRANSPORT = 'Transport',
  WIND = 'Wind',
}

export const mitigationTypeList = [
  { value: MitigationTypes.AGRICULTURE.valueOf(), label: 'Agriculture' },
  { value: MitigationTypes.SOLAR.valueOf(), label: 'Solar' },
  { value: MitigationTypes.BIOMASS_ENERGY.valueOf(), label: 'Biomass energy', disabled: true },
  { value: MitigationTypes.CCS.valueOf(), label: 'CCS', disabled: true },
  { value: MitigationTypes.CEMENT.valueOf(), label: 'Cement', disabled: true },
  {
    value: MitigationTypes.COAL_MINE.valueOf(),
    label: 'Coal bed/mine methane',
    disabled: true,
  },
  { value: MitigationTypes.EE_HOUSEHOLDS.valueOf(), label: 'EE households', disabled: true },
  { value: MitigationTypes.EE_INDUSTRY.valueOf(), label: 'EE industry', disabled: true },
  {
    value: MitigationTypes.EE_OWN_GENERATION.valueOf(),
    label: 'EE own generation',
    disabled: true,
  },
  { value: MitigationTypes.EE_SERVICE.valueOf(), label: 'EE service', disabled: true },
  { value: MitigationTypes.EE_SUPPLY_SIDE.valueOf(), label: 'EE supply side', disabled: true },
  {
    value: MitigationTypes.ENERGY_DISTRIBUTION.valueOf(),
    label: 'Energy distribution',
    disabled: true,
  },
  { value: MitigationTypes.FORESTRY.valueOf(), label: 'Forestry', disabled: true },
  {
    value: MitigationTypes.FOSSIL_FUEL.valueOf(),
    label: 'Fossil fuel switch',
    disabled: true,
  },
  { value: MitigationTypes.FUGITIVE.valueOf(), label: 'Fugitive', disabled: true },
  { value: MitigationTypes.GEOTHERMAL.valueOf(), label: 'Geothermal', disabled: true },
  { value: MitigationTypes.HFC_PFCS_SF6.valueOf(), label: 'HFCs, PFCs, SF6', disabled: true },
  { value: MitigationTypes.HYDRO.valueOf(), label: 'Hydro', disabled: true },
  { value: MitigationTypes.LANDFILLS.valueOf(), label: 'Landfills', disabled: true },
  { value: MitigationTypes.MARINE.valueOf(), label: 'Marine', disabled: true },
  {
    value: MitigationTypes.METHANE_AVOIDANCE.valueOf(),
    label: 'Methane avoidance',
    disabled: true,
  },
  { value: MitigationTypes.N20.valueOf(), label: 'N2O', disabled: true },
  { value: MitigationTypes.TRANSPORT.valueOf(), label: 'Transport', disabled: true },
  { value: MitigationTypes.WIND.valueOf(), label: 'Wind', disabled: true },
];
