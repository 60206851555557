import React from 'react';

const SignUp = () => {
  return (
    <div>
      <span>SignUp</span>
    </div>
  );
};

export default SignUp;
