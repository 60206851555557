export enum Sector {
  Energy = 'Energy',
  Health = 'Health',
  Education = 'Education',
  Transport = 'Transport',
  Manufacturing = 'Manufacturing',
  Hospitality = 'Hospitality',
  Forestry = 'Forestry',
  Waste = 'Waste',
  Agriculture = 'Agriculture',
  Other = 'Other',
}
